import React from "react";
import PropTypes from "prop-types";
import {graphql, navigate} from "gatsby";
import Layout from "../components/Layout";
import cross from "../../static/img/cross.png";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import BreadCrumb from "../components/BreadCrumb";
import {FaAngleDoubleDown} from "react-icons/fa";
import circleLeft from '../img/elliplse.png';

export const WhatWeDoPageTemplate = ({
                                       sectionOne,
                                       sectionTwo,
                                       sectionThree,
                                       sectionFour,
                                     }) => {
  const links = [
    {label: 'Home', value: '/'}
  ];
  return (
    <div className="page-container what-we-do-page">
      <div className={'container'}><BreadCrumb links={links} currentPage={'What we do'}/></div>
      <section className="sectionOne container">
        <div className="content-with-image">
          <div className={"section-image"}>
            <PreviewCompatibleImage
              imageInfo={{image: sectionOne.personImage, alt: "Person Image"}}
            />
          </div>
          <div className="text-content">
            <div className="first-line">What we do</div>
            <div className="second-line">
              We are a consulting firm offering advisory services in marketing
              and business strategy.
            </div>
            <div className="third-line">
              Our work largely involves the formulation and implementation of
              strategies and plans; - in the nature of choices of markets,
              product / service offerings, competitive positioning, marketing
              actions and supporting initiatives that reinforce the strategies.
            </div>
          </div>
        </div>
      </section>
      <div className="base"><FaAngleDoubleDown size={36} className={'down-arrow'}/></div>
      <section className="sectionTwo container">
        <div className="section-title">
          <div>{sectionTwo.title.revenue}</div>
          <div>{sectionTwo.title.marketShare}</div>
          <div>{sectionTwo.title.pricingPower}</div>
        </div>
        <div className="section-description">{sectionTwo.description}</div>
      </section>
      <section className="sectionThree container">
        <div className="section-title">
          <div>{sectionThree.title}</div>
        </div>
        <div className="section-description">{sectionThree.description}</div>
      </section>
      <section className="sectionFour container">
        {/* <div className="section-title">{sectionFour.title}</div> */}
        <div className="mobile-view">
          <div className="first-image">
            <PreviewCompatibleImage
              imageInfo={{image: sectionFour.centerImage}}
            />
          </div>
          <div className="image">
            <PreviewCompatibleImage
              imageInfo={{image: sectionFour.mLeftRight}}
            />
          </div>
        </div>
        <div className="desktop-view">
          <div className="left">
            <PreviewCompatibleImage
              imageInfo={{image: sectionFour.leftImage}}
            />{" "}
          </div>
          <div className="center">
            <PreviewCompatibleImage
              imageInfo={{image: sectionFour.centerImage}}
            />
          </div>
          <div className="right">
            <PreviewCompatibleImage
              imageInfo={{image: sectionFour.rightImage}}
            />
          </div>
        </div>
      </section>
      <section className="sectionFive container">
        <div className="text-caption">Click here to find</div>
        <button
          className="button-wwdnd"
          onClick={() => navigate("/what-we-do-not-do")}
        >
          <div className="button-alignment">
            <div className="tex">What we do not do</div>
            <div>
              <img src={cross} alt={"cross"}/>
            </div>
          </div>
        </button>
      </section>
      <img className={'left-circle'} src={circleLeft} alt={'decoration'}/>
      <img className={'right-circle'} src={circleLeft} alt={'decoration'}/>
    </div>
  );
};

const WhatWeDoPage = ({data}) => {
  const {frontmatter} = data.markdown;

  return (
    <Layout>
      <WhatWeDoPageTemplate
        sectionOne={frontmatter.sectionOne}
        sectionTwo={frontmatter.sectionTwo}
        sectionThree={frontmatter.sectionThree}
        sectionFour={frontmatter.sectionFour}
      />
    </Layout>
  );
};

WhatWeDoPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default WhatWeDoPage;

export const pageQuery = graphql`
  query WhatWeDoPageTemplate {
    markdown: markdownRemark(
      frontmatter: { templateKey: { eq: "what-we-do-page" } }
    ) {
      frontmatter {
        topsection {
          bulbimage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sectionOne {
          shortDescription
          longDescription
          personImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          baseImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobilePersonImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        sectionTwo {
          title {
            revenue
            marketShare
            pricingPower
          }
          description
        }
        sectionThree {
          title
          description
        }
        sectionFour {
          title
          leftImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          centerImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          rightImage {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mLeftRight {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;

import React from 'react';
import breadcrumb from "../img/breadcrumb.png";
import {Link} from "gatsby";
import {FiChevronRight} from "react-icons/fi";


export default function ({links, currentPage}) {
  return <div className={'breadcrumb'}>
    <img src={breadcrumb} alt={'bulb'}/>
    <div>
      <ul>
        {links.map((link, index) => <li key={link}>
          <Link to={link.value}>{link.label}</Link>
          {index < links.length - 1 && <FiChevronRight/>}
        </li>)}
      </ul>
      <div>{currentPage}</div>
    </div>
  </div>;
}
